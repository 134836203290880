<template>
  <div class="text-center" v-if="resolutionScreen < 500">
    <v-snackbar
      v-model="shower"
      :timeout="listrecheckdownload.timeout"
      bottom
      class="v-snack__content"
    >
      <v-card color="transparent" elevation="0" class="mt-3 pa-3" width="320">
        <p v-if="listrecheckdownload.status === 'start'">
          {{ $t("adminrecheckimap.checkpleaseweit") }}
        </p>
        <p v-else-if="listrecheckdownload.status === 'checkallemail'">
          {{ $t("adminrecheckimap.checkdoc") }}
          <span class="float-right"
            >{{ listrecheckdownload.nowemail }} /
            {{ listrecheckdownload.allcheckemail }}</span
          >
        </p>
        <p
          v-else-if="
            listrecheckdownload.status === 'download' &&
              listrecheckdownload.percen === 100
          "
        >
          {{ $t("adminrecheckimap.downloaddocsuccess") }}
          <span class="float-right"
            ><v-icon color="green">mdi-check-bold</v-icon></span
          >
        </p>
        <p
          v-else-if="
            listrecheckdownload.status === 'download' &&
              listrecheckdownload.percen >= 0
          "
        >
          {{ $t("adminrecheckimap.nowdownload") }}
          <span class="float-right" v-if="listrecheckdownload.percen > 0">{{
            listrecheckdownload.percen
          }}</span>
        </p>
      </v-card>
      <div class="px-n12 mr-n2 mt-n2">
        <v-progress-linear
          v-if="listrecheckdownload.status === 'start'"
          color="green"
          indeterminate
          :height="10"
        ></v-progress-linear>
      </div>
    </v-snackbar>
  </div>
  <div class="text-center" v-else>
    <v-snackbar
      v-model="shower"
      :timeout="listrecheckdownload.timeout"
      bottom
      class="v-snack__content"
    >
      <v-card color="transparent" elevation="0" width="500" class="mt-3 pa-3">
        <p v-if="listrecheckdownload.status === 'start'">
          <span>{{ $t("adminrecheckimap.checkpleaseweit") }}</span>
          <!-- <v-progress-circular
            class="float-right pa-0 mt-0"
            indeterminate
            color="green"
            height="8"
          ></v-progress-circular> -->
        </p>
        <p v-else-if="listrecheckdownload.status === 'checkallemail'">
          {{ $t("adminrecheckimap.checkdoc") }}
          <span class="float-right"
            >{{ listrecheckdownload.nowemail }} /
            {{ listrecheckdownload.allcheckemail }}</span
          >
        </p>
        <p
          v-else-if="
            listrecheckdownload.status === 'download' &&
              listrecheckdownload.percen === 100
          "
        >
          {{ $t("adminrecheckimap.downloaddocsuccess") }}
          <span class="float-right"
            ><v-icon color="green">mdi-check-bold</v-icon></span
          >
        </p>
        <p
          v-else-if="
            listrecheckdownload.status === 'download' &&
              listrecheckdownload.percen >= 0
          "
        >
          {{ $t("adminrecheckimap.nowdownload") }}
          <span v-if="listrecheckdownload.percen > 0" class="float-right">{{
            listrecheckdownload.percen
          }}</span
          ><v-progress-circular
            v-else
            indeterminate
            color="green"
            height="8"
            class="mt-n1 float-right"
          ></v-progress-circular>
        </p>
      </v-card>
      <div class="px-n12 mr-n2 mt-n2">
        <v-progress-linear
          v-if="listrecheckdownload.status === 'start'"
          color="green"
          indeterminate
          :height="10"
        ></v-progress-linear>
      </div>
    </v-snackbar>
  </div>
</template>
<script>
import { mapState, mapGetters } from "vuex";
import "sweetalert2/src/sweetalert2.scss";
export default {
  data: function() {
    return {};
  },
  comments: {},
  props: ["show", "listrecheckdownload"],
  computed: {
    ...mapState(["username", "authorize", "account_active", "color"]),
    ...mapState({ processloader: "loading" }),
    ...mapGetters([
      "dataUsername",
      "dataAuthorize",
      "dataAccountActive",
      "dataAccesstoken",
      "dataBusinessProfile",
      "dataCitizenProfile",
      "dataDepartmentAccessId",
      "dataAccountId",
    ]),
    resolutionScreen() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 220;
        case "sm":
          return 400;
        case "md":
          return 500;
        case "lg":
          return 600;
        case "xl":
          return 800;
      }
    },
    shower: {
      get() {
        return this.show;
      },
      set(value) {
        if (!value) {
          this.$emit("closedialog");
        }
      },
    },
  },
  methods: {},
};
</script>
<style>
.v-snack__content {
  padding-top: 0px;
  padding-right: 0px;
  padding-bottom: 0px;
  padding-left: 0px;
}
</style>
